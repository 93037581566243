import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Header = () => {        
    const images = useStaticQuery(graphql`
        query MyQuery {
        header: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        instagram: file(relativePath: { eq: "social/instagram.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        twitter: file(relativePath: { eq: "social/twitter.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        facebook: file(relativePath: { eq: "social/facebook.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        }
    `);

    return (<div>
                <div id="mainHeader">
                    <Img className={'coverImage'} fluid={images.header.childImageSharp.fluid} alt="facebook logo"/>
                    <div id="contactInformation">
                        <div className={'contactDiv'}>
                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                            <b>07771367237 (<span className={'colouredTitle'}>24/7</span>)</b>
                        </div>
                        <div className={'contactDiv'}>
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <b>enquiries@jackelectrician.co.uk</b>
                        </div>
                        <div id={'socialIcons'}>
                            <a href="https://www.facebook.com/Jack-OConnor-Electrician-103220247939869">
                                <Img className={'socialIcon rounded'} fluid={images.facebook.childImageSharp.fluid} alt="facebook logo"/>
                            </a>
                            <a href="https://twitter.com/o_electrician">
                                <Img className={'socialIcon rounded'} fluid={images.twitter.childImageSharp.fluid} alt="twitter logo"/>
                            </a>
                            <a href="https://www.instagram.com/jack_electrician_uk">
                                <Img className={'socialIcon'} fluid={images.instagram.childImageSharp.fluid} alt="instagram logo"/>
                            </a>
                        </div>
                    </div>                
                </div>
                <ul className={'flatList'} style={{ 
                    padding: '0px', 
                    width: '100%',
                    listStyle: 'none',
                    marginLeft: 'none' ,
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    marginTop: '0px'
                }}>
                    <li><Link activeClassName={'activeNav'} to='/'>About us</Link></li>
                    <li><Link activeClassName={'activeNav'} to='/services'>Services</Link></li>
                    <li><Link activeClassName={'activeNav'} to='/reviews'>Reviews</Link></li>
                    <li><Link activeClassName={'activeNav'} to='/contact'>Contact</Link></li>
                </ul>
                <div id={'mobileNavigation'}>
                    <ul>
                        <li><Link activeClassName={'activeNav'} to='/'>About us</Link></li>
                        <li><Link activeClassName={'activeNav'} to='/services'>Services</Link></li>
                        <li><Link activeClassName={'activeNav'} to='/reviews'>Reviews</Link></li>
                        <li><Link activeClassName={'activeNav'} to='/contact'>Contact</Link></li>
                    </ul>                    
                </div>
            </div>
            );
}

export default Header;