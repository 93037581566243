import React from "react";

export default class Container extends React.Component{    
    render(){
        return (
            <div id={'container'}>
                <div id={'innerContainer'}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}