import React from "react";

export default class Footer extends React.Component{
    render(){
        return (
            <div className='footer'>
                <div id='contentHolder'>
                    <p id='copyright'>© Jack O'Connor 2020 - All Rights Reserved</p>
                    <p id='credit'>Site created by Jason Attwood</p>                    
                </div>
            </div>
        );
    }
}