import React from "react";
import Container from "../components/Container";
import Header from "../components/Header";
import Footer from "./Footer";
import SEO from "./SEO";

export default class Base extends React.Component{
    render(){
        return (
            <div>
                <SEO title={this.props.title}/>
                <Header/>
                <Container>
                    {this.props.children}
                </Container>
                <Footer/>
            </div>            
        );
    }
}